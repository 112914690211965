import { safeLocalStorage } from '@ifixit/utils';

type RecentlySeenEntry = {
   lastSeen: number;
   count: number;
};

type RecentlySeenMap = {
   [id: string]: RecentlySeenEntry;
};

const localStorageKey = 'recently-seen-banners';

export const getRecentlySeenMap = () =>
   safeLocalStorage.getJson<RecentlySeenMap>(localStorageKey) || {};

const setRecentlySeenMap = (recentlySeenMap: RecentlySeenMap): boolean =>
   safeLocalStorage.setJson(localStorageKey, recentlySeenMap);

const seenBanners = [] as string[];

export const updateEntry = (id: string): void => {
   if (seenBanners.includes(id)) {
      return;
   }

   const recentlySeenMap = getRecentlySeenMap();
   const entry = recentlySeenMap[id];
   if (entry) {
      entry.lastSeen = Date.now();
      entry.count += 1;
   } else {
      recentlySeenMap[id] = {
         lastSeen: Date.now(),
         count: 1,
      };
   }
   setRecentlySeenMap(recentlySeenMap);
   seenBanners.push(id);
};

export const resetEntry = (id: string): void => {
   const recentlySeenMap = getRecentlySeenMap();
   const entry = recentlySeenMap[id];
   if (entry) {
      entry.lastSeen = 0;
      entry.count = 0;
   }
   setRecentlySeenMap(recentlySeenMap);
};
